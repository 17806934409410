import { doSubscribeAjax, isFormValid } from "./util";

/**
 * Homepage Subscribe Block
 *
 * On sign up button click, it goes to the /newsletters page with prefilled values.
 */
function Block() {
	this.blocks = [];

	this.init = function () {
		this.register();
	};

	this.register = () => {
		const $subscribeForm = $(".subscribe-form"); // jQuery selector
		$subscribeForm.on("submit", this.submit);
		// this.blocks.find(".subscribe-form").on("submit", this.submit);
	};

	this.submit = (e) => {
		const $form = $(e.target);
		if (!isFormValid($form, false)) {
			e.preventDefault();
			return;
		}

		// Optionally handle form submission here
		// doSubscribeAjax($form);
	};
}

export default new Block();
