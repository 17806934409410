class IasVideo {
  constructor(field) {
    $(window).on("ad:ias:update", this, this.update);
    $(window).on("ad:ias:playeradded", this, this.playerAdded);
  }

  playerAdded(event, myPlayer) {
    if ($("#" + myPlayer.id_).hasClass("ias")) {
      return;
    }
    myPlayer.on("ima3-ads-manager-loaded", function (event) {
      let video = document.getElementById(this.id_);
      let adsManager = this.ima3.adsManager;

      let config = {
        anId: "929481",
        campId: "1000x1v",
        chanId: "21700271609",
        ias_xps: "clicktoplay",
        ias_xbp: "1",
        ias_xar: "0",
      };

      // Init IAS Integration
      googleImaVansAdapter.init(google, adsManager, video, config);

      google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
    });

    $("#" + myPlayer.id_).addClass("ias");
  }
}

export default IasVideo;
