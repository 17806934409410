/**
 * PlayList Video Player
 */

function PlaylistPlayer() {
    if (!$(".playlist-player-module").length) {
        return;
    }

    function layoutPlayer() {
        var playerHeight = $('#player').height();
        var watchLiveHeight = $('.jw-player-more').height();

        if ($(window).width() < 768) {
           $("#playlist").height(264);
        } else {
            $("#playlist").height(playerHeight - watchLiveHeight - 65);
        }

    }

    let efocResizeTimer = 0;
    $(window).on("resize", function () {
        clearTimeout(efocResizeTimer);
        efocResizeTimer = setTimeout(() => {
            layoutPlayer();
        }, 10);
    });

    $(window).on("load", function() {
        layoutPlayer();
    })

    function updateUI() {
        observeDone = true;
        layoutPlayer();
    }

    let observeDone = false;
    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            let shouldUpdate = false;
            if ( !observeDone ) {
                if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                    for (let i = 0; i < mutation.addedNodes.length; i++) {
                        let node = mutation.addedNodes[i];
                        if (node.nodeType === 1 && (node.querySelector('.playlist-item') || node.classList.contains('playlist-item') )) {
                            shouldUpdate = true;
                            break;
                        }
                    }
                }

                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    shouldUpdate = true;
                }

                if (shouldUpdate) {
                    updateUI();
                }
            }
        });
    });

    const config = {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['class']
    };

    observer.observe(document.body, config);
}

export default PlaylistPlayer;
