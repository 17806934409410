/**
 * Event Upcoming Experiences Promo block
 */
const EventUpcomingExperiences = () => {
	const init = () => {
		$('[data-js="event-upcoming-experiences-slider"]').each((index, el) => {
			new Swiper(el, {
				navigation: {
					nextEl: $(el).parent().find(".swiper-button-next")[0],
					prevEl: $(el).parent().find(".swiper-button-prev")[0],
				},
				slidesPerView: 1,
				spaceBetween: 16,
				loop: false,

				// Responsive breakpoints
				breakpoints: {
					// when window width is >= 400px
					400: {
						slidesPerView: "auto",
					},
				},
			});
		});
	};

	return { init };
};

EventUpcomingExperiences().init();
