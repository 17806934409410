// Callback on script loaded
export default function extScriptLoaded(test, fn) {
	const callbackTimer = setInterval(function() {
		let call = false;
		try {
			call = test.call();
		} catch (e) {}

		if (call) {
			clearInterval(callbackTimer);
			fn.call();
		}
	}, 100);
}
