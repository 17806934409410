function tvGuide() {
  if (!$(".tv-guide-module").length) {
    return;
  }

  var playerInstance;
  var fullscreenMode = false;

  /** Timeline Swiper */
  $(".time-slider-swiper").each((index, el) => {
    const $container = $(el).closest(".schedule-channels-slider");
    const $tracksToSync = $container.find(
      ".video-slider-row, .current-time-indicator-track"
    );

    const timeSlider = new Swiper(el, {
      slidesPerView: "auto",
      navigation: {
        nextEl: $container.find(".swiper-button-next")[0],
        prevEl: $container.find(".swiper-button-prev")[0],
      },
    });

    timeSlider.on("setTranslate", function onSliderMove() {
      $tracksToSync.css({ "margin-left": `${this.translate}px` });
    });
  });

  const calcTimeIndicatorPos = () => {
    $(".current-time-indicator").each((index, el) => {
      // Calculate the time difference between browser time now and timelineStart
      const timelineStart = $(el)
        .closest(".schedule-tabcontent")
        .find("#timeline_start")
        .val(); // e.g. 2023-06-29T13:00:00Z
      const start = new Date(timelineStart);
      const now = new Date();

      const diff = now - start;
      if (diff < 0) {
        return;
      }

      const diffMinutes = Math.floor(diff / 1000 / 60);
      const totalMinutes =
        $(el).closest(".schedule-container").data("slotcount") * 60; // X hours

      const percent = (diffMinutes / totalMinutes) * 100;

      // Position the Current time indicator
      $(el).css("left", `${percent}%`).show();
    });
  };

  /** Current time indicator on the timeline */
  if ($(".current-time-indicator").length) {
    calcTimeIndicatorPos();
    setInterval(calcTimeIndicatorPos, 1000 * 60); // Update every minute
  }

  /** Resize TV Guide Main video player */
  function resizeTVGuide() {
    // let new_width;

    // if ($(window).width() < 768) {
    //   // Below 768px: Keep the video width full. It's fine to overflow browser height.
    //   new_width = window.innerWidth;
    // } else {
    //   // Keep everything within the viewport.
    //   let max_height = window.innerHeight;
    //   if (!fullscreenMode && $(".site-header").length) {
    //     max_height = max_height - $(".site-header").outerHeight(true);
    //   }
    //   max_height = max_height - $(".tv-guide-module__header").outerHeight(true);
    //   max_height = max_height - $(".schedules").outerHeight(true);
    //   max_height = max_height - $(".tv-guide-module__footer").outerHeight(true);
    //   new_width = max_height * (16 / 9) + 40; // Padding 40px
    // }

    // $(".video-container").css("max-width", `${new_width}px`);

    // if ($(".tv-guide-module-inner").hasClass("video-active")) {
    //   $(".schedules .tv-guide-module__container").css(
    //     "max-width",
    //     `${new_width}px`
    //   );
    // } else {
    //   $(".schedules .tv-guide-module__container").css("max-width", "");
    // }
  }
  resizeTVGuide();

  function startVideoPlayback() {
    // Play the video on the cover click.
    $(".tv-guide__video-overlay").on("click", function (e) {
      e.preventDefault();
      $(".tv-guide__video").addClass("active");
      if (playerInstance) {
        playerInstance.play();
      }
    });
  }

  const updateFullScreenMode = (isFullScreen) => {
    fullscreenMode = isFullScreen;
    $("html").toggleClass("tv-guide-fullscreen", fullscreenMode);
  };

  $(".schedules-toggle").on("click", function () {
    updateFullScreenMode(true);

    // Check if TV Guide is showing the main video or the schedules tabs.
    if (!$(".tv-guide-module-inner").hasClass("schedule-active")) {
      $(".schedules-toggle").text("Hide Program Guide");

      $(".tv-guide-module-inner").addClass("schedule-active");
      $(".tv-guide-module-inner").removeClass("video-active");

      // Scroll to top of the section.
      $("html, body").animate(
        {
          scrollTop:
            $(".tv-guide-module").offset().top - $(".site-header").height(),
        },
        300
      );
    } else {
      $(".schedules-toggle").text("See Program Guide");
      $(".tv-guide-module-inner").removeClass("schedule-active");
      $(".tv-guide-module-inner").addClass("video-active");
    }
    resizeTVGuide();
  });

  $(".schedules-header a").on("click", function () {
    const $this = $(this);

    // Skip if the tab is already active.
    if ($this.hasClass("active")) {
      return;
    }

    // Update active classes of tab.
    $(".schedules-header a.active").removeClass("active");
    $this.addClass("active");

    // Update active classes of tab content.
    $(".schedule-tabcontent").removeClass("active");
    $($this.data("target")).addClass("active");
  });

  let efocResizeTimer = 0;
  $(window).on("resize", function () {
    if ($(".tv-guide-module-inner").hasClass("video-active")) {
      clearTimeout(efocResizeTimer);
      efocResizeTimer = setTimeout(() => {
        resizeTVGuide();
      }, 100);
    }
  });

  // Live channel init
  function loadJWPlayer(mediaId, channelId, playerId, muted) {
    const VOD_CONFIG = {
      pid: playerId,
      playlist: `https://cdn.jwplayer.com/v2/media/${mediaId}`,
      repeat: false,
      autostart: true,
      mute: muted,
      aspectratio: "16:9",
      sharing: { sites: ["facebook", "twitter", "email", "linkedin"] },
      floating: false,
      interactive: {},
      advertising: false,
    };

    const UPDATE_FREQUENCY = 10 * 1e3;
    const LIVESTREAM_COMPLETE_ERROR = 230001;
    const HLS_BUFFER_STALL_WARNING = 334001;
    const MAX_RETRIES = 3;

    let currentEventId;
    let intervalId;

    playerInstance = jwplayer("tv-guide-video-player").setup(VOD_CONFIG);
    checkChannelStatus();

    // Catch video player completions
    playerInstance.on("complete", handleLivestreamFinished); // VOD, just in case
    playerInstance.on("playlistComplete", handleLivestreamFinished); // Live

    playerInstance.on("error", (error) => {
      if (playerInstance.getPlaylistItem().mediaid !== currentEventId) {
        return;
      }
      if (error.code === LIVESTREAM_COMPLETE_ERROR) {
        handleLivestreamFinished();
      }
    });

    playerInstance.on("warning", (warn) => {
      if (playerInstance.getPlaylistItem().mediaid !== currentEventId) {
        return;
      }
      if (warn.code === HLS_BUFFER_STALL_WARNING) {
        handleLivestreamFinished();
      }
    });

    function checkChannelStatus() {
      if (!intervalId) {
        intervalId = setInterval(checkChannelStatus, UPDATE_FREQUENCY);
      }
      getChannelStatus(channelId).then(
        (channelStatus) => {
          if (channelStatus["status"] === "active") {
            const eventId = channelStatus["current_event"];
            if (currentEventId === eventId) {
              return;
            }
            currentEventId = eventId;
            intervalId = clearInterval(intervalId);
            configurePlayer(eventId).catch((error) => {});
          }
        },
        (error) => {
          intervalId = clearInterval(intervalId);
        }
      );
    }

    async function configurePlayer(eventId) {
      let playlist;
      let attempts = 0;

      while (!playlist) {
        try {
          playlist = await getPlaylist(eventId);
        } catch (e) {
          ++attempts;
          if (attempts >= MAX_RETRIES) {
            playlist = {
              playlist: [
                {
                  mediaid: eventId,
                  file: `https://cdn.jwplayer.com/live/events/${eventId}.m3u8`,
                },
              ],
            };
            break;
          }
          await sleep(2 ** (attempts - 1) * 5 * 1000);
        }
      }

      playerInstance.setConfig({
        repeat: false,
      });
      playerInstance.load(playlist.playlist);
      playerInstance.play();

      // Show Live Indicator for the TV Guide.
      $(".tv-guide-module").addClass("tv-guide-module--live");
    }

    async function fetchJSON(url, init) {
      return await fetch(url, init).then((response) => {
        if (!response.ok) {
          throw new Error(`Unable to fetch ${url}: ${response.statusText}`);
        }
        return response.json();
      });
    }
    function getChannelStatus(channelId) {
      return fetchJSON(
        `https://cdn.jwplayer.com/live/channels/${channelId}.json`
      );
    }

    function getPlaylist(mediaId) {
      return fetchJSON(`https://cdn.jwplayer.com/v2/media/${mediaId}`, {
        cache: "no-cache",
      });
    }

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  }

  function loadJWPlayerVideo() {
    const videoID = $('input[name="video_id"]').val();
    const liveChannelID = $('input[name="live_channel_id"]').val();
    const playerId = $('input[name="player_id"]').val();
    loadJWPlayer(videoID, liveChannelID, playerId, true);
  }
  loadJWPlayerVideo();

  // Automatically respond to video ID changes
  $('input[name="video_id"]').on("change", function () {
    const videoID = $('input[name="video_id"]').val();
    const liveChannelID = $('input[name="live_channel_id"]').val();
    const playerId = $('input[name="player_id"]').val();
    loadJWPlayer(videoID, liveChannelID, playerId, false);
  });

  function handleLivestreamFinished() {
    let videoID;
    let liveChannelID;

    // Remove Live Indicator for the TV Guide.
    $(".tv-guide-module").removeClass("tv-guide-module--live");

    // get and play the next live show for this player instance.
    $.ajax({
      url: pubstack.ajaxUrl,
      method: "POST",
      dataType: "JSON",
      async: false,
      data: {
        action: "get_current_live_schedule",
        post_id: pubstack.postID,
      },
    }).done((response) => {
      if (response && response.channel_id) {
        liveChannelID = response.channel_id;
        videoID = response.video_id;
      }
    });

    if (!videoID || !liveChannelID) {
      // Something could be wrong. Play the first video of the schedule anyways.
      liveChannelID = $(".video-slide-col")
        .first()
        .find(".jw_channel_id")
        .val();
      videoID = $(".video-slide-col").first().find(".segment_video_id").val();
    }

    startVideoPlayback();
  }

  // Click function which detects the requested video and channel ID and starts playing.
  $(".watch-video-trigger").on("click", function () {
    let segmentVideoID = $(this).data("video_id"),
      segmentChannelID = $(this).data("channelid");

    $(".tv-guide__video div.player-wrapper").attr(
      "id",
      "tv-guide-video-player"
    );
    $('input[name="video_id"]').val(segmentVideoID).change();
    $('input[name="live_channel_id"]').val(segmentChannelID).change();

    startVideoPlayback();
    $(".schedules-toggle").trigger("click");
  });

  $(".return-to-site").on("click", function () {
    if (false !== $(".tv-guide-module-inner").hasClass("schedule-active")) {
      $(".schedules-toggle").trigger("click");
    }

    updateFullScreenMode(false);
    resizeTVGuide();
  });
}

export default tvGuide;
