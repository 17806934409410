/**
 * Javascript for Analytics
 *
 **/

function Analytics() {
  this.init = function () {
    $(window).on("analytics:pageView", this, this.ga);
    $(window).on("analytics:pageView", this, this.parsely);
    $(window).on("analytics:event:nativetout", this, this.native);
    $(window).on("analytics:event:iscrollView", this, this.iscrollView);

    window.ess_analytics = this;
    $("audio").each((index, audio) => {
      $(audio).on("play", () => {
        dataLayer.push({ event: "analytics:audioplay:ga" });
      });
    });
    this.parsely();
  };

  this.ga = function (event, hash, post_data) {
    if (typeof post_data !== "object") {
      post_data = JSON.parse(post_data);
    }

    let set_pageview = {
      event: "analytics:pageView:ga",
      page: post_data.page_slug,
      title: post_data.post_title,
      location: post_data.page_url,
    };

    dataLayer.push(set_pageview);
  };

  this.parsely = function (event, hash, post_data) {
    if (typeof PARSELY !== "undefined" && PARSELY.beacon) {
      var parsely_url = location.href.replace(location.hash, "");
      var parsely_urlref = location.href;

      PARSELY.beacon.trackPageView({
        url: parsely_url,
        urlref: parsely_urlref,
        js: 1,
      });
    }
  };

  this.iscrollView = function (event, slot_number) {
    let push_event = {
      event: "analytics:iscroll:ga",
      eventCategory: "iscroll",
      eventAction: "view",
      eventLabel: slot_number,
    };
    dataLayer.push(push_event);
  };

  this.native = function (event, tout, eventAction) {
    const analytics = JSON.parse(tout.dataset.analytics);
    let push_event = {
      event: "analytics:campaign:ga",
      eventCategory: analytics.location,
      eventAction,
      eventLabel: analytics.client_name,
      gacd25: analytics.post_id,
      na_client_id: analytics.client,
      na_campaign_id: analytics.campaign,
      na_campaign_name: analytics.campaign_name,
      title: analytics.title,
      location: analytics.url,
    };
    dataLayer.push(push_event);
  };
}

export default Analytics;
