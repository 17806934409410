// Disable Leaderboard setting for mobile view
function disableLeaderboard() {
  var disable_leaderboard_setting = $("#essence-leaderboard-setting").val();
  var isMobileDevice = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  if (
    disable_leaderboard_setting === "disable_mobile_on_article" &&
    isMobileDevice
  ) {
    $("#essence_leaderboard").remove();
  }
}

export default disableLeaderboard;
