import { doSubscribeAjax, isFormValid } from "./util";

function Unsubscribe() {
	this.$form = $("#newsletter-unsubscribe .subscribe-form");

	this.init = () => {
		if (this.$form.length) {
			this.register();
		}
	};

	this.register = () => {
		this.$form.on("submit", (e) => this.submit(e));
	};

	this.submit = (e) => {
		e.preventDefault();

		if (this.isFormValid() & isFormValid(this.$form, false)) {
			doSubscribeAjax(this.$form, null, null, () => {
				if (!this.$form.data("redirect")) {
					$("html, body").animate({ scrollTop: 0 }, 500);
				}
			});
		}
	};

	this.isFormValid = () => {
		let isValid = true;

		// Validate opt-out reason.
		if (this.$form.find("[name=sfmc_unsub_reason]:checked").length) {
			this.$form.find(".error-invalid-list").hide();
		} else {
			this.$form.find(".error-invalid-list").show();
			isValid = false;
		}

		return isValid;
	};
}

export default new Unsubscribe();
