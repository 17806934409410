function PlaylistSchedule() {

    this.$modal = $("#playlist-schedule-modal");

    this.init = () => {
		if (this.$modal.length) {
			this.register();
		}
	};

    this.show = () => {
        this.$modal.fadeIn();
        $("body").addClass("modal-open");
	};

	this.close = () => {
		this.$modal.fadeOut(400);
		$("body").removeClass("modal-open");
	};

	this.register = () => {
		this.$modal.find(".close").on("click", this.close);
        $("#playlist_schedule").on("click", this.show);
	};
}

export default new PlaylistSchedule();
