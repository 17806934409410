import { doSubscribeAjax, isFormValid } from "./util";

function Modal() {
	this.$modal = $("#newsletter-subscribe-modal");
	this.$checkbox = this.$modal.find("#terms_agree");
	this.$subscribeButton = this.$modal.find("button[type='submit']");
	this.$emailModal = this.$modal.find(".email-subscribe-modal");
	this.$smsModal = this.$modal.find(".sms-subscribe-modal");
	this.$backdrop = this.$modal.find(".modal-backdrop");

	this.init = () => {
		const displayed = localStorage.getItem("ess_nl_popup_displayed");
		if (this.$modal.length && !displayed) {
			this.showEmailModal();
			this.register();
			this.handleCheckbox();
		} else {
			this.remove();
		}
	};

	this.showEmailModal = () => {
		$(document).one("scroll", () => {
			this.$modal.fadeIn();
			this.$emailModal.attr("aria-hidden", "false").removeAttr("inert");
			this.$emailModal.fadeIn();
			this.$backdrop.fadeIn();
			$("body").addClass("modal-open");
			this.$emailModal.focus();
		});
	}

	this.showSmsModal = () => {
		this.$modal.fadeIn();
		this.$smsModal.attr("aria-hidden", "false").removeAttr("inert");
		this.$smsModal.fadeIn();
		this.$backdrop.fadeIn();
		$("body").addClass("modal-open");
		this.$smsModal.focus();
	};

	this.show = () => {
		$(document).one("scroll", () => {
			this.$modal.fadeIn();
			$("body").addClass("modal-open");
		});
	};

	this.remove = () => {
		this.$modal.remove();
	};

	this.scheduleSmsModal = () => {
        setTimeout(() => {
            this.showSmsModal();
        }, 120000);
    };

	this.closeModal = (isPhoneAvailable) => {
		if (isPhoneAvailable) {
			this.$modal.fadeOut(1000, this.remove);
			$("body").removeClass("modal-open");
			localStorage.setItem("ess_nl_popup_displayed", true);
		} else {
			this.$emailModal.fadeOut(400, () => {
				this.$emailModal.attr("aria-hidden", "true").attr("inert", "true");
				this.$emailModal.find(":focus").blur();
				$("body").removeClass("modal-open");
				this.$backdrop.fadeOut();
				this.scheduleSmsModal();
			});
		}
    };

	this.close = () => {
		this.$modal.fadeOut(1000, this.remove);
		$("body").removeClass("modal-open");
		localStorage.setItem("ess_nl_popup_displayed", true);
	};

	this.register = () => {
		this.$modal.find(".close").on("click", this.close);
		this.$modal.find(".btn-close").on("click", this.close);
		this.$modal.find(".subscribe-form").on("submit", (e) => this.submit(e));
	};

	this.submit = (e) => {
		e.preventDefault();
		const $form = $(e.target);

		if ($form.attr('id') === "smsSubscribe") {
			if (isFormValid($form, false)) {
				doSubscribeAjax($form, () => setTimeout(this.close, 300));
			}
		} else if (isFormValid($form)) {
			doSubscribeAjax($form, (isPhoneAvailable) => setTimeout(() => this.closeModal(isPhoneAvailable), 3000));
		}
	};

	this.handleCheckbox = () => {
		this.$subscribeButton.prop("disabled", !this.$checkbox.is(":checked"));

		this.$checkbox.on("change", () => {
			this.$subscribeButton.prop("disabled", !this.$checkbox.is(":checked"));
		});
	};
}

const phoneInputField = document.querySelector("#modalSubscribePhone");

if (phoneInputField) {
	const ERROR_INVALID_PHONE_CLASS = "invalid-phone";

	const phoneInput = window.intlTelInput(phoneInputField, {
		utilsScript:
			"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
	});

	// Add an event listener to check when the phone number changes
	phoneInputField.addEventListener("blur", function () {
		if (phoneInput.isValidNumber()) {
			// Set the value to the formatted number (E164 format)
			phoneInputField.value = phoneInput.getNumber(
				intlTelInputUtils.numberFormat.E164
			);
			phoneInputField.classList.remove(ERROR_INVALID_PHONE_CLASS);
		} else {
			// Optionally handle invalid phone number
			phoneInputField.classList.add(ERROR_INVALID_PHONE_CLASS);
			console.log("Invalid phone number");
		}
	});

	// Function to move the divs inside the wrapper
	function moveErrorDivs() {
		const wrapper = document.querySelector(".iti"); // Get the wrapper element created by intlTelInput

		if (wrapper) {
			// Select the error divs
			const invalidPhoneDiv = document.querySelector(".error-invalid-phone");
			const invalidRequiredDiv = document.querySelector(
				".error-invalid-required"
			);

			// Append the divs to the wrapper
			if (invalidPhoneDiv) {
				wrapper.appendChild(invalidPhoneDiv);
			}
			if (invalidRequiredDiv) {
				wrapper.appendChild(invalidRequiredDiv);
			}
		}
	}

	// Function to handle closing of error messages
	function handleCloseError(event) {
		event.preventDefault();
		const errorDiv = event.target.parentElement; // Get the parent div of the close button
		const className = errorDiv.className; // Get the class name of the error div
		const classToRemove = className.replace("error-", ""); // Remove prefix to get the class to remove

		phoneInputField.classList.remove(classToRemove); // Remove the corresponding class from the phone input
	}

	// Wait until the intlTelInput is loaded and then move the divs
	window.addEventListener("load", () => {
		moveErrorDivs();

		// Add click event listeners to error close buttons
		const closeButtons = document.querySelectorAll(".error-close");
		closeButtons.forEach((button) => {
			button.addEventListener("click", handleCloseError);
		});
	});
}

export default new Modal();
