/**
 * Functionality to power the automatic and manual injection of editorial
 * defined widgets into the body of content.
 *
 * This is the file that Webpack is compiling into injector.[hash].js
 */

import "./sass/tvguide.scss";
import tvGuide from "./js/blocks/tv-guide";
$(function () {
  tvGuide();
});
