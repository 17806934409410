/**
 * Event VOD Promo block
 */
const EventVodPromo = () => {
	const init = () => {
		$('[data-js="event-vod-promo-slider"]').each((index, el) => {
			new Swiper(el, {
				navigation: {
					nextEl: $(el).parent().find(".swiper-button-next")[0],
					prevEl: $(el).parent().find(".swiper-button-prev")[0],
				},
				slidesPerView: 1,
				spaceBetween: 24,
				loop: false,
				// Responsive breakpoints
				breakpoints: {
					// when window width is >= 800px
					800: {
						slidesPerView: 2,
					},
					// when window width is >= 1024px
					1024: {
						slidesPerView: 3,
					},
				},
			});
		});
	};

	return { init };
};

EventVodPromo().init();
