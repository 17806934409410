function NativeTout() {
	this.init = () => {
		this.initObserver();

		// Track page blocks.
		this.trackBlocks();

		// Track on the infinite scrolled articles.
		$(window).on("ess_article_loaded", this, this.trackRightrail);
		$(window).on("ess_article_loaded", this, this.trackArticleHeader);

		// Track on the initial article load.
		if ($(".pubstack-post-header").length) {
			this.trackRightrail(null, $(".pubstack-post-header").first());
			this.trackArticleHeader(null, $(".pubstack-post-header").first());
		}
	};

	/**
	 * Impression observer.
	 */
	this.initObserver = () => {
		// Observer to detect Native Tout.
		const nativeToutObserver = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0) {
						$(window).trigger("analytics:event:nativetout", [
							entry.target,
							"impression",
						]);
						nativeToutObserver.unobserve(entry.target);
					}
				});
			},
			{ threshold: 0 }
		);
		this.observer = nativeToutObserver;
	};

	/**
	 * Track sponsored headlines impression/click in the article rightrail.
	 */
	this.trackRightrail = function (e, header) {
		const nativeTout = header.next().find(".entry-rightrail .js-native-tout");
		if (nativeTout.length) {
			nativeTout.each((index, tout) => {
				// Track impression
				this.observer.observe(tout);

				// Track click
				$(tout)
					.closest(".blog-data")
					.find("a.article-title")
					.on("click", function () {
						$(window).trigger("analytics:event:nativetout", [tout, "click"]);
					});
			});
		}
	};

	/**
	 * Track sponsored headlines impression/click in the page blocks.
	 */
	this.trackBlocks = () => {
		const topicNativeTouts = $(".essence-promo, .essence-topic").find(
			".js-native-tout"
		);
		if (topicNativeTouts.length) {
			topicNativeTouts.each((index, tout) => {
				// Track impression
				this.observer.observe(tout);

				// Track click
				$(tout)
					.closest(".essence-promo, .essence-topic")
					.find(".essence-topic__link")
					.on("click", function () {
						$(window).trigger("analytics:event:nativetout", [tout, "click"]);
					});
			});
		}
	};

	/**
	 * Track sponsored headline note in the article content header - which means the article is actually viewed.
	 */
	this.trackArticleHeader = function (e, header) {
		const nativeTout = header.next().find(".article__header .js-native-tout");
		if (nativeTout.length) {
			nativeTout.each((index, tout) => {
				// Track view
				$(window).trigger("analytics:event:nativetout", [tout, "view"]);
			});
		}
	};
}

export default new NativeTout();
