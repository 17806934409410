/**
 * JWPlayer
 *
 **/

const { default: extScriptLoaded } = require("./utils/script-loaded");

window.is_essence_video_playing = false;
window.essence_video_currently_playing = false;
window.essence_video_in_view = null;

function VideoJw() {
  this.observer = null;
  this.init = function () {
    extScriptLoaded(
      () => typeof jwplayer !== "undefined",
      () => {
        this.setupPlayers();
        this.initObserver();
        $(window).on("jw:loadplayers", this, this.loadPlayers);
        $(window).on("jw:loadplayers", this, this.observeNewPlayers);
      }
    );
  };

  this.setupPlayers = () => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin around the root
      threshold: 0.5, // Fire the callback when 50% of the player is visible
    };

    while (typeof (i = jwplayerQueue.shift()) !== "undefined") {
      const { instanceId, playerId, config } = i;

      // Get the JWPlayer element
      const playerElement = document.getElementById(playerId);

      // Intersection Observer callback function
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting ||
            $("#" + playerId)
              .parent()
              .hasClass("no-ads")
          ) {
            // If player is 50% in view, play the video
            jwplayer(playerId).play();
          } else {
            // If player is not in view, pause the video
            jwplayer(playerId).pause();
          }
        });
      };

      // Create an Intersection Observer
      const observer = new IntersectionObserver(handleIntersection, options);

      // Observe the player element
      observer.observe(playerElement);

      // Setup JWPlayer instance with config
      const instance = jwplayer(playerId).setup(config);
      if (instanceId) {
        window[instanceId] = instance;
      }
    }
  };

  this.initObserver = () => {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          const $videoBox = $(entry.target);
          const player_id = $videoBox.addClass("played").data("video");

          if (!entry.isIntersecting) {
            // Out of view. Enable floating of the current playing video.
            if (window.essence_video_currently_playing === player_id) {
              const myPlayer = window["playerInstance_" + player_id];
              if (myPlayer) {
                myPlayer.setFloating(true);
              }
            }

            // Remove the video from the in-view list
            if (window.essence_video_in_view === player_id) {
              window.essence_video_in_view = null;
            }
          } else if (entry.intersectionRatio > 0) {
            const myPlayer = window["playerInstance_" + player_id];
            // In view. Disable floating.
            if (myPlayer) {
              myPlayer.setFloating(false);
            }

            // Store the video currently in view
            window.essence_video_in_view = player_id;

            // Check if no one else is playing or is forced to play.
            if (
              $videoBox.attr("force-autoplay") ||
              !window.is_essence_video_playing
            ) {
              // Autoplay the new video
              const myPlayer = window["playerInstance_" + player_id];
              if (myPlayer) {
                myPlayer.play();
              }
            }
          }
        });
      },
      { threshold: 0.5 }
    );
  };

  this.observeNewPlayers = () => {
    if (!this.observer) {
      this.initObserver();
    }
    $(".jw-video-box")
      .not(".observed")
      .each((index, box) => {
        this.observer.observe(box);
        $(box).addClass("observed");

        this.observer.observe(box, (entry) => {
          // Check if entry is 50% visible, then autoplay
          if (entry.intersectionRatio >= 0.5) {
            const player_id = $(box).data("video");
            const myPlayer = window["playerInstance_" + player_id];
            if (myPlayer) {
              myPlayer.play();
            }
          }
        });
      });
  };

  this.onPlay = (player_id, event) => {
    // Check if user clicked play.
    if (event.playReason === "interaction") {
      // Stop the existing autoplay/pip
      if (
        window.essence_video_currently_playing &&
        window.essence_video_currently_playing !== player_id
      ) {
        const oldPlayer =
          window["playerInstance_" + window.essence_video_currently_playing];
        oldPlayer.pause();
        // Force to disable floating.
        oldPlayer.setFloating(false);
      }
    }

    window.is_essence_video_playing = true;
    window.essence_video_currently_playing = player_id;
  };

  this.onStop = (player_id, paused = false) => {
    if (window.essence_video_currently_playing === player_id) {
      window.essence_video_currently_playing = null;
      window.is_essence_video_playing = false;

      // When video is paused, if another video is already in view, play it.
      // Because at this point, intersection observer is not triggered.
      if (
        paused &&
        window.essence_video_in_view &&
        window.essence_video_in_view !== player_id
      ) {
        const playerInView =
          window["playerInstance_" + window.essence_video_in_view];
        playerInView.play();
      }
    }
  };

  this.loadPlayers = () => {
    if (typeof jwplayer === "undefined") {
      return;
    }

    this.setupPlayers();

    const instance = this;

    $(".jw-video-box")
      .not(".loaded")
      .each(function (index, box) {
        const player_id = $(box).data("video");
        const myPlayer = window["playerInstance_" + player_id];

        if (!myPlayer) {
          return;
        }

        myPlayer.on("play", function (event) {
          instance.onPlay(player_id, event);
        });

        myPlayer.on("adPlay", function (event) {
          instance.onPlay(player_id, event);
        });

        myPlayer.on("pause", function (event) {
          instance.onStop(player_id, true);
        });
        myPlayer.on("adPause", function (event) {
          instance.onStop(player_id, true);
        });

        // Completing a PiP video does not close the PiP window
        // Completing a non-PiP video negates the PiP for that video from opening
        myPlayer.on("complete", function (event) {
          if (!this.getFloating()) {
            instance.onStop(player_id);
          }
        });
        myPlayer.on("adComplete", function (event) {
          if (!this.getFloating()) {
            instance.onStop(player_id);
          }
        });

        // with error
        myPlayer.on("error", function (event) {
          if (!this.getFloating()) {
            instance.onStop(player_id);
          }
        });
        myPlayer.on("adError", function (event) {
          if (!this.getFloating()) {
            instance.onStop(player_id);
          }
        });

        if ($(box).closest(".article__media").length) {
          const $article = $(box).closest(".article");
          myPlayer.on("captionsList", (event) => {
            $article.find(".article__scripts").remove();
            // Check that we have a valid transcript file before showing iframe
            if ("tracks" in event && "id" in event["tracks"][1]) {
              const captions_url = event["tracks"][1]["id"];
              if (captions_url.endsWith(".srt")) {
                const html = `<div class="article__scripts">
								<div class="transcript-header"><div class="article__scripts-title">Transcript</div><button class="view-all-button">VIEW FULL TRANSCRIPT</button></div>
								<div class="article__scripts-content">
									<iframe src="${captions_url}" width="640" height="360" frameborder="0" scrolling="auto"></iframe>
								</div>
								</div><div class="view-all-transcript-model"><div class="content"><div class="model-transcript-header"><div class="article__scripts-title">Transcript</div><a class="close-transcript">X</a></div><div class="article__scripts-content">
                <iframe src="${captions_url}" width="640" height="360" frameborder="0" scrolling="auto"></iframe>
              </div></div></div>`;
                $article.find(".article__tags").before(html);

                $(".view-all-button").click(function () {
                  $(".view-all-transcript-model").css("display", "block");
                });

                $(".close-transcript").click(function () {
                  $(".view-all-transcript-model").css("display", "none");
                });
              }
            }
          });
        }

        // On pip close, pause the video.
        $(box).on("click", ".jw-float-icon", function () {
          myPlayer.pauseAd(true);
          myPlayer.pause();
          instance.onStop(player_id);
        });

        $(window).trigger("ad:ias:playeradded", [myPlayer]);
        $(window).trigger(`video_loaded_${player_id}`, [myPlayer]);
        $(box).addClass("loaded");
      });

    // promo custom video auto scroll play
    if ($(".essence-video-promo .essence-video-promo__custom-video").length) {
      $(".essence-video-promo .essence-video-promo__custom-video iframe").each(
        function () {
          var iframeSrc = $(this).attr("src");
          var iframeSrcArr = iframeSrc.split("&");

          var iframeSrc = "";
          var index = 0;
          for (index = 0; index < iframeSrcArr.length; index++) {
            if (
              iframeSrcArr[index] !== "autoplay" &&
              iframeSrcArr[index] !== "muted"
            ) {
              iframeSrc += iframeSrcArr[index];
              iframeSrc += "&";
            }
          }

          iframeSrc = iframeSrc.substr(0, iframeSrc.length - 1);

          var iframeSrcAutoPlay = iframeSrc + "&muted&autoplay";

          var checkVideoPlaying = false;

          var windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
          var self = this;
          jQuery(window).on(
            "scroll",
            essThrottle(function () {
              if (
                $(window).scrollTop() >= $(self).offset().top - windowHeight &&
                $(window).scrollTop() <=
                  $(self).offset().top + $(self).outerHeight()
              ) {
                if (!checkVideoPlaying) {
                  $(self).attr("src", iframeSrcAutoPlay);
                  checkVideoPlaying = true;
                }
              } else {
                if (checkVideoPlaying) {
                  $(self).attr("src", iframeSrc);
                  checkVideoPlaying = false;
                }
              }
            })
          );
        }
      );
    }
  };
}

module.exports = VideoJw;
