import extScriptLoaded from "../utils/script-loaded";

export default function articleOembed() {
	const renderInstagram = () => {
		extScriptLoaded(
			() => typeof window.instgrm !== "undefined",
			() => {
				window.instgrm.Embeds.process();
			}
		);
	};

	const iscrollRenderInstagram = (event, header) => {
		if (header.next().find(".ess-embed-content.instagram").length) {
			renderInstagram();
		}
	};

	const initialRenderInstagram = () => {
		if ($(".ess-embed-content.instagram").length) {
			renderInstagram();
		}
	};

	const init = () => {
		initialRenderInstagram();
		$(window).on("omebed:renderInstagram", iscrollRenderInstagram);
	};

	init();
}
